* { box-sizing: border-box; }

body {
  min-width: 100vw;
  min-height: 100vh;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dbf1ff+0,f0f9ff+100 */
  background: #c4e9ff; /* Old browsers */
  background: -moz-linear-gradient(top,  #c4e9ff 0%, #a3daff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #c4e9ff 0%,#a3daff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #c4e9ff 0%,#a3daff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c4e9ff', endColorstr='#a3daff',GradientType=0 ); /* IE6-9 */

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import './App';