.App {
  text-align: center;

  .loading {
    margin-top: 100px;
  }
}

.error {
  color: #f00;
  font-style: italic;
  font-weight: bold;
  font-size: 0.875rem;
}

.search-bar {
  padding: 0.375rem;
  background: rgba(255,255,255,0.4);

  input, button {
    height: 30px;
    padding: 0.25rem 0.5rem;
    background: #fff;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
  }

  input {
    margin-right: 0.875rem;
  }

  button {
    $btn_color: rgb(61, 33, 0);
    color: $btn_color;
    background: rgba(255, 184, 54, 0.6);
    border-color: $btn_color;
    transition: all 0.25s linear;
    cursor: pointer;

    &:hover {
      background: rgba(255, 184, 54, 1);
      color: #fff;
      font-weight: bold;
      border-color: #fff;
    }
  }
}

.display-celcius {
  height: 28px;
  font-size: 0.875rem;
  line-height: 2em;

  .switch-container {
    height: 28px;
    width: 46px;
    margin-right: 4px;
    background: #eee;
    display: inline-block;
    vertical-align: middle;
    border: 2px solid #ccc;
    border-radius: 16px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.35);
    cursor: pointer;
  
    .switch-indicator {
      $diameter : 20;
      width: $diameter+px;
      height: $diameter+px;
      margin: 2px 0 0 4px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 15px;
      box-shadow: 0 1px 2px rgba(0,0,0,0.35);
      transition: margin 0.25s ease-in-out;
    }
  
    &.on {
      background-color: rgb(119, 240, 119);
  
      .switch-indicator {
        margin-left: 18px;
      }
    }
  }
}


.five-day-forecast {
  width: calc(100vw - 2.5rem);
  margin: 0 auto;
  padding: 2.5rem 0 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  li {
    width: 200px;
    margin: 0.5rem;
    padding: 1rem;
    background: rgba(255, 255, 255,0.5);
    text-align: center;
    list-style-type: none;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 1.25rem;

    h2 {
      margin-top: 0;
    }

    &:first-child {
      background: rgb(255, 249, 237);
      border: 1px solid rgb(255, 208, 0);
    }

    table {
      margin: 0 auto;
      border-collapse: collapse;

      thead {
        font-size: 0.875rem;
      }

      tbody {
        font-weight: bold;
      }

      tr {
        padding: 0.25rem;
      }

      td {
        width: 33.3333%;
        padding: 0.25rem;

        &:nth-child(1) {
          background: rgb(183, 223, 255);
        }

        &:nth-child(2) {
          background: rgb(255, 229, 218);
        }
      }
    }
  }
}

footer {
  margin-top: 30px;
  padding: 20px 0;
  font-size: 0.75rem;
  line-height: 1.125em;

  p {
    margin: 4px;

    a {
      color: rgb(34, 100, 199);
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}